<template>
  <v-speed-dial
    v-if="isVisible"
    absolute
    bottom
    fixed
    right
    class="mr-md-2"
    :class="smAndDown ? 'mb-n3' : 'mb-n2'"
  >
    <template v-slot:activator>
      <v-card
        @click="openBookSelection"
        flat
        :ripple="false"
        class="ai-balloon d-flex flex-md-column align-center"
        style="cursor: pointer; opacity: 1;background-color: transparent;">
        <v-img
          contain
          class="mt-n3"
          :src="`${cdnUrl}/assets/images/item/btn_select_ai.svg`"
          :width="smAndDown ? 100 : 160"
          :height="smAndDown ? 100 : 160"
        />
      </v-card>
    </template>
  </v-speed-dial>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "vb-book-selection-button",
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
    }
  },
  methods: {
    ...mapMutations('openAi', [
      'mutateParentURL'
    ]),
    openBookSelection() {
      const currentRoute = this.$route.path;
      this.mutateParentURL(currentRoute);
      if(this.getChatItems.length) {
        // チャットアイテムが存在する場合は、結果ページに移動
        this.$router.push({ path: '/book-selection/result' });
      } else {
        if(currentRoute === '/') {
          this.$router.push({path: '/book-selection'});
        } else {
          // トップページ以外の場合は、HTTPリクエストによる画面遷移を行う
          this.notSPATransitions();
        }
      }
    },
    notSPATransitions() {
      location.href = '/book-selection';
    }
  },
  computed: {
    ...mapGetters('openAi', [
      'getChatItems'
    ]),
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    currentRoute() {
      return this.$route.path;
    },
    isVisible() {
      const hiddenRoutes = [
        '/confirm',
        '/completed',
        '/shipping/form',
        '/shipping/confirm',
        '/card/form',
      ];
      return !hiddenRoutes.includes(this.currentRoute);
    },
  }
}
</script>

<style scoped lang="scss">
.v-speed-dial--absolute {
  z-index: 5 !important;
}
</style>